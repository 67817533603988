<template>
  <div class="data">
    <h1>ประวัติการสั่งซื้อ</h1>
    <div class="search">
      <div class="input-group mb-6">
        <div class="col-sm-2">
          <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked />
          <label class="btn btn-outline-success" for="btnradio1">ออเดอร์</label>
          <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" />
          <label class="btn btn-outline-success" for="btnradio2">ลอตเตอรี่</label>
        </div>
        <div class="col-sm-2" v-if="hasDateLotto">
          <Field v-slot="{ value }" name="lotto_date" as="select" class="form-control">
            <option value="" selected="selected">Select a lotto date</option>
            <option v-for="date in dateLotto" :key="date" :value="date.lotto_date" :selected="value && value.includes(date)">{{ date.lotto_date }}</option>
          </Field>
        </div>
        <div class="col-sm-3" style="margin-left: 25px">
          <div class="col-sm-12">
            <input type="text" class="form-control" id="search_value" placeholder="ค้นหาจากผู้ขายหรือหมายเลขออเดอร์" />
          </div>
        </div>
        <div class="col-sm-2">
          <input type="submit" class="btn btn-primary" value="ค้นหา" />
        </div>
      </div>
    </div>
    <h5>จำนวน {{ countOrder }} รายการ</h5>
    <div class="grid-collapse">
      <div v-if="haspendingOrder">
        <div v-for="item of pendingOrder" :key="item">
          <div class="form-data">
            <div class="grid">
              <div class="data-detail">
                <div class="col-sm-8">
                  <label for="order_id" class="col-sm-12 col-form-label head">หมายเลขออเดอร์</label>
                </div>
                <div class="col-sm-8">
                  <label for="order_id" class="col-sm-12 col-form-label">{{ item.id }}</label>
                </div>
              </div>
              <div class="data-detail">
                <div class="col-sm-8">
                  <label for="round" class="col-sm-12 col-form-label head">งวดวันที่</label>
                </div>
                <div class="col-sm-8">
                  <label for="round" class="col-sm-12 col-form-label">{{ item.lotto_date }}</label>
                </div>
              </div>
              <div class="data-detail">
                <div class="col-sm-8">
                  <label for="but_date" class="col-sm-12 col-form-label head">วันที่สั่งซื้อ</label>
                </div>
                <div class="col-sm-8">
                  <label for="but_date" class="col-sm-12 col-form-label">{{ item.created_at }}</label>
                </div>
              </div>
              <div class="data-detail">
                <div class="col-sm-8">
                  <label for="seller" class="col-sm-12 col-form-label head">ผู้ขาย</label>
                </div>
                <div class="col-sm-8">
                  <label for="seller" class="col-sm-12 col-form-label">{{ item.reseller.user.user_profile.name }}</label>
                </div>
              </div>
              <div class="data-detail">
                <div class="col-sm-8">
                  <label for="score" class="col-sm-12 col-form-label head">คะแนนที่ได้รับ</label>
                </div>
                <div class="col-sm-8">
                  <label for="score" class="col-sm-12 col-form-label">{{ item.order_item.length }}</label>
                </div>
              </div>
            </div>
            <div class="col-sm-12 status">
              <label for="status">สถานะ : {{ item.id }}</label>
              <label for="status" class="status-detail">{{ item.order_status }}</label>
            </div>
            <button class="btn upload">อัพโหลดสลิป</button>
            <router-link :to="{ name: 'History', params: { id: item.id } }"><button class="btn detail">รายละเอียด</button></router-link>
          </div>
        </div>
      </div>
      <div v-if="hasfinishOrder">
        <div v-for="item of finishOrder" :key="item">
          <div class="form-data2">
            <div class="grid2">
              <div class="data-detail">
                <div class="col-sm-8">
                  <label for="order_id" class="col-sm-12 col-form-label head">หมายเลขออเดอร์</label>
                </div>
                <div class="col-sm-8">
                  <label for="order_id" class="col-sm-12 col-form-label">{{ item.id }}</label>
                </div>
              </div>
              <div class="data-detail">
                <div class="col-sm-8">
                  <label for="round" class="col-sm-12 col-form-label head">งวดวันที่</label>
                </div>
                <div class="col-sm-8">
                  <label for="round" class="col-sm-12 col-form-label">{{ item.lotto_date }}</label>
                </div>
              </div>
              <div class="data-detail">
                <div class="col-sm-8">
                  <label for="but_date" class="col-sm-12 col-form-label head">วัน-เวลาที่สั่งซื้อ</label>
                </div>
                <div class="col-sm-12">
                  <label for="but_date" class="col-sm-12 col-form-label">{{ item.created_at }}</label>
                </div>
              </div>
              <div class="data-detail">
                <div class="col-sm-8"></div>
              </div>
              <div class="data-detail">
                <div class="col-sm-8">
                  <label for="seller" class="col-sm-12 col-form-label head">ชื่อตัวแทน</label>
                </div>
                <div class="col-sm-8">
                  <label for="seller" class="col-sm-12 col-form-label">{{ item.reseller.user.user_profile.name }}</label>
                </div>
              </div>
              <div class="data-detail">
                <div class="col-sm-8"></div>
              </div>
              <div class="data-detail">
                <div class="col-sm-8">
                  <label for="score" class="col-sm-12 col-form-label head">รหัสตัวแทน</label>
                </div>
                <div class="col-sm-8">
                  <label for="score" class="col-sm-12 col-form-label">{{ item.reseller.reseller_code }}</label>
                </div>
              </div>
              <div class="data-detail">
                <div class="col-sm-8">
                  <label for="score" class="col-sm-12 col-form-label head">คะแนนที่ได้รับ</label>
                </div>
                <div class="col-sm-8">
                  <label for="score" class="col-sm-12 col-form-label">0</label>
                </div>
              </div>
            </div>
            <div class="col-sm-12 status">
              <label for="status">สถานะ : </label>
              <label for="status">{{ item.order_status }}</label>
            </div>
            <router-link :to="{ name: 'History', params: { id: item.id } }"><button class="btn detail">รายละเอียด</button></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from '../services/user.service'
import { Form, Field } from 'vee-validate'
export default {
  name: 'ShowHistory',
  components: {
    Form,
    Field
  },
  data() {
    return {
      content: {},
      dateLotto: {},
      pendingOrder: [],
      finishOrder: [],
      countOrder: 0,
      hasDateLotto: false,
      haspendingOrder: false,
      hasfinishOrder: false
    }
  },
  methods: {},
  async created() {
    if (!this.$store.state.auth.user) {
      this.$router.push('/login')
    }
    try {
      const resDateLotto = await UserService.getDateLotto()
      if (resDateLotto.data != null) {
        this.dateLotto = resDateLotto.data
        this.hasDateLotto = true
      }
      const orderHistory = await UserService.getHistory()
      if (orderHistory.data != null) {
        this.countOrder = orderHistory.data.length
        for (const x in orderHistory.data) {
          if (orderHistory.data[x].order_status === 'pending') {
            this.pendingOrder.push(orderHistory.data[x])
            this.haspendingOrder = true
          } else {
            this.finishOrder.push(orderHistory.data[x])
            this.hasfinishOrder = true
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
}
</script>

<style></style>
